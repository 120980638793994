import React, { FC } from 'react'
import './styles.scss';
import { ContentPage } from '../../content-page'


export const RebatePage: FC= () => {
  return (
    <>
    {/*  <div className='rebate-rules-image'/>*/}
    <p>
      Because Jason has low overhead costs, he can offer buyers a portion
      of his agent commission. However, this must be approved by the buyer's lender and be
      given at the time of closing. The agent rebate must be included by the closing attorney on the closing documents.
    </p>
    <p>
     The buyers rebate is considered an Interested Party Contribution (IPC). Freddie Mac and Fannie Mae
     have regulations on limits as to how this money can be used in a real estate transation. The rebate
     is ONLY allowed to cover closing cost on your home. Some examples of closing costs are attorney fees, mortgage origination fees,
     and 6 months of home insurance/taxes collected up front. These cost add up to serveral thousand dollars, even as much as 2% - 5% of price
      of the house.
    </p>
    <p>
    Typically, buyers are able to utilize the full around of the buyer's rebate given by NC Realty Group. But depending on
    the terms of your loan and the amount of closing cost the seller is offering, sometimes the limits allow buyers to recieve
    only a portion of the rebate. For a good article about the interested party contributions and the rules associated with them,
    <a href='https://mymortgageinsider.com/seller-paid-closing-cost-maximums-seller-contributions-conventional-fha-va-usda-loans-7355/#:~:text=For%20all%20FHA%20loans%2C%20the,contribute%206%25%20of%20the%20value' target='blank'> read here</a>.
    </p>
    </>
  )
}
