import React, { FC } from 'react'
import './styles.scss';
import { ContentPage } from '../../content-page'


export const MLSSearchPage: FC= () => {
  return (
    <>
  {/*  <div className='contact-image'/> */}

      <iframe className='search-mls' src={'https://idx.trianglemls.com/index.php?unique_frame_link=0486425001472611249&query_id=1'} height={"1000px"} width={"100%"} frameBorder={"0"}>
      </iframe>
    </>
  )
}
