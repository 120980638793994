import React, { FC } from 'react'
import './styles.scss';

import { Parallax } from 'react-scroll-parallax';
import { Header } from '../../header'
import { Buying } from './buying'
import { Selling } from './selling'
import { References } from './references'
import { Footer } from '../../footer'
import heroImage from '../../assets/images/hero-min.jpeg';
import { Link } from "react-router-dom";

export const HomePage: FC= () => {
  return (
    <div className="home">
        <div className="header-container">
          <div className='header-flex-row'>
            <div className='header-cell'>
            <Header />
            </div>
          </div>
        </div>

      <div className='main-container'>

          <Parallax  y={[-20, 20]}>
            <img className='hero' src={heroImage}></img>
          </Parallax>

          {/*  <img className='hero' src={heroImage}></img>  */}
        <div className='main-content'>
            <div className='hero-nav-container'>
              <div className='tag-text-container'>
                <h2 className='tag-line-text list'>List for ONLY 3.9%</h2>
                <h2 className='tag-line-text'>Buyers: Share My Commission!</h2>
              </div>
            {/*  <div className='tag-line-text'>Full service you need at the price you want</div> */}
              <div className='nav-button-row'>
            {    // <div className='nav-button'><div>BUY</div></div>
                // <div className='nav-button'><div>SELL</div></div>
                // <div className='nav-button'><div>LEARN MORE</div></div>
              }
          <Link to='/learnmore' ><div className='nav-button-diff'><div>WHAT MAKES US DIFFERENT?</div></div></Link>
              </div>
            </div>
            <div className='body-container'>
              <div className='body-content'>
               <div className='spacer' />
                <Buying />
                 <div className='spacer' />
                <Selling />
                 <div className='spacer' />
                <References />
              </div>
            </div>

        </div>
        <Footer />
      </div>
    </div>
  )
}
