import React, { FC } from 'react'
import './styles.scss';
import mlsImage from '../assets/images/mls.svg';
import mlsRealtorImage from '../assets/images/mls-realtor.svg';
import equalHousingImage from '../assets/images/equalhousing.svg';
import ncrgImage from '../assets/images/nc-realty-logo-white-outline.svg';
import { Link } from "react-router-dom";


export const Footer: FC= ({}) => {


  return (
    <>
    <div className='blue-bar' />
    <div className='footer'>
      <div className='footer-row'>
        <div className='links-column'>
          <div className='links-row'>
            <Link to='/buying'><div className='link-cell'>Buy</div></Link>
            <div className='link-separator' >|</div>
          <Link to='/selling'>  <div className='link-cell'>Sell</div></Link>
            <div className='link-separator' >|</div>
          <Link to='/learnmore'>  <div className='link-cell'>Learn More</div></Link>
            <div className='link-separator' >|</div>
          <Link to='/contact'>  <div className='link-cell'>Contact</div></Link>
            <div className='image-spacer'></div>
          <a href="tel:+1-919-247-8574">  <div className='link-cell'>(919) 247-8574</div></a>
          </div>
          <Link to='/'><div className='copyright'>&#169; {new Date().getFullYear()} NC Realty Group, LLC</div></Link>
        </div>
        <div className='img-row'>
          <Link to='/'><img className='image' src={ncrgImage} /></Link>
          <div className='image-spacer'/>
          <img className='image-realtor' src={mlsRealtorImage} />
          <div className='image-spacer'/>
          <img className='image' src={equalHousingImage} />
        </div>
      </div>
    </div>
    </>
  )
}
