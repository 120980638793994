import React, { FC } from 'react'
import './styles.scss';
import { ContentPage } from '../../content-page'


export const SellingPage: FC= () => {
  return (
  <>
  <div className='sell-page-image'/>
  <p>Jason will provide a full service listing at a lower cost for the area. With
  15+ years of experience, he'll help you prepare your house and get the listing just right. He is able to charge less to
  list your house because his overhead costs are minimal. He can pass these
  savings on to you without sacrificing the quality of service.
  </p>
  </>
  )
}
