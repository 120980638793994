import React, { FC } from 'react'
import './styles.scss';
import { ContentPage } from '../../content-page'
import ncrgImage from '../../assets/images/nc-realty-logo-v2.svg';


export const ContactPage: FC= () => {
  return (
    <>
    <div className='contact-image'/>
    <p>
      When you are ready to discuss your real estate needs, feel free to call, text, or email.
    </p>

      <div className='contact-row'>
        <img className='ncrg-image' src={ncrgImage} />
        <div className='contact-into-col'>
            <div className='contact-info-row'>
              <div className='info-label'><b>Jason Brown:</b></div>
              <div>Owner/Broker-in-Charge</div>
            </div>
            <div className='contact-info-row'>
              <div className='info-label'><b>Phone Number:</b></div>
              <div> <a href="tel:+1-919-247-8574">(919) 247-8574</a></div>
            </div>
            <div className='contact-info-row'>
              <div className='info-label'><b>Email:</b></div>
              <div><a href="mailto:jasonbrown@ncrealtygroup.com"> jasonbrown@ncreatlygroup.com</a></div>
            </div>
        </div>
      </div>
    </>
  )
}
