import React, { FC,useEffect } from 'react'
import './styles.scss';
import { Header } from '../header'
import { Footer } from '../footer'
import { TitleHeader } from '../title-header'
import { Route, Switch } from "react-router-dom";
import { BuyingPage } from '../pages/buying-page'
import { SellingPage } from '../pages/selling-page'
import { LearnMorePage } from '../pages/learn-more-page'
import { ContactPage } from '../pages/contact-page'
import { RebatePage } from '../pages/rebate-page'
import { MLSSearchPage } from '../pages/mls-search-page'


export const ContentPage: FC<{titleText:string,phraseText:string}>= ({titleText,phraseText,children}) => {

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <div className='content-page'>
      <div className="header-container">
        <div className='header-flex-row'>
          <div className='header-cell'>
          <Header />
          </div>
        </div>
      </div>
      <div className='children-wrapper'>
        <div className='body-container'>
          <div className='body-content'>
            <TitleHeader titleText={titleText} phraseText={phraseText} />
                <Switch>
                    <Route path="/selling"><SellingPage /></Route>
                    <Route path="/buying"><BuyingPage /></Route>
                    <Route path="/sell"><SellingPage /></Route>
                    <Route path="/buy"><BuyingPage /></Route>
                    <Route path="/seller"><SellingPage /></Route>
                    <Route path="/buyer"><BuyingPage /></Route>
                    <Route path="/learnmore"><LearnMorePage /></Route>
                    <Route path="/contact"><ContactPage /></Route>
                    <Route path="/rebate"><RebatePage /></Route>
                    <Route path="/mlssearch"><MLSSearchPage /></Route>
                </Switch>
          </div>
       </div>
       <div className='footer-container'>
          <Footer />
       </div>
   </div>
</div>
  )
}
