import React from 'react';

import { HomePage } from './pages/home-page'
import { ContentPage } from './content-page'

import { Route, Switch } from "react-router-dom";
import './App.scss';

function App() {

  return (
    <>
      <Switch>
          <Route exact path="/">{<HomePage />}</Route>
          <Route path="/buying"><ContentPage titleText={'Buying'} phraseText={'Split the commission 50/50'} /></Route>
          <Route path="/selling"> <ContentPage titleText={'Selling'} phraseText={'List for ONLY 3.9%'}/></Route>
          <Route path="/learnmore"><ContentPage titleText={'Learn More'} phraseText={'What makes us different?'} /></Route>
          <Route path="/contact"> <ContentPage titleText={'Contact'} phraseText={'We are ready to help!'}/></Route>
          <Route path="/rebate"> <ContentPage titleText={`Buyer's Rebate`} phraseText={'How does it work?'}/></Route>
          <Route path="/mlssearch"> <ContentPage titleText={`MLS Search`} phraseText={'Search the MLS'}/></Route>
      </Switch>
    </>
  );
}


export default App;
