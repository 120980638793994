import React, { FC,useState,useEffect } from 'react'
import './styles.scss';

import { TitleHeader } from '../../../title-header'
import buyerImage from '../../../assets/images/buying/buyers-image-min.jpg';
import buyerImageMobile from '../../../assets/images/buying/buyers-image-mobile-min.png';
import { Link } from "react-router-dom";


export const Buying: FC= ({}) => {

  const [ calcResult, setCalcResult ] = useState("")
  const [ homePrice, setHomePrice ] = useState('$500,000')
  const [ isHomePriceValid, setHomePriceValid ] = useState(true)
  const [ commission, setCommission ] = useState('2.4%')
  const [ isCommissionValid, setCommissionValid ] = useState(true)

  useEffect(() => {
    calculate()
  },[homePrice,commission])

  const calculate = () =>{
    console.log('Buying: ENTER calculate')
    let result = null;

    let commissionAsNum = Number.parseFloat(commission)
    let homePriceAsNum = getHomePriceAsNum(homePrice);

    if(homePriceAsNum && commissionAsNum && validateCommission(commissionAsNum) && validateHomePrice(homePriceAsNum)){
      result = homePriceAsNum * (commissionAsNum/100)
      result = result/2
      result = Number(result).toLocaleString();
    } else {
      result = ''
    }
    setCalcResult(result)
  }

  const updateHomePrice = (event:any) => {
    let valueAsString = event.target.value;

    let valueAsNum = getHomePriceAsNum(valueAsString);

    if(valueAsNum && !isNaN(valueAsNum) ){
      valueAsString = valueAsNum.toString()
      valueAsString = valueAsString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      valueAsString = '$' + valueAsString
    }

    setHomePrice(valueAsString)
  }

  const getHomePriceAsNum = (homePriceAsString:string) => {
    console.log('Buying: ENTER getHomePriceAsNum')

    if(homePriceAsString && homePriceAsString.length >0){
      homePriceAsString = homePriceAsString.replaceAll('$','')
      homePriceAsString = homePriceAsString.replaceAll(',','')
    }

    return Number.parseInt(homePriceAsString);
  }

  const validateHomePrice = (homePrice:any) => {
    let isValid= false;

  //  let homePriceAsNum = Number.parseInt(homePrice)
    let homePriceAsNum = getHomePriceAsNum(homePrice);

    if(homePriceAsNum && !isNaN(homePriceAsNum) ){
      if(homePriceAsNum > 0){
        isValid=true;
      }
    }

    setHomePriceValid(isValid)

    return isValid;
  }

  const updateCommission = (event:any) => {

    let valueAsString = event.target.value;

    let valueAsNum = Number.parseFloat(event.target.value);
    if(valueAsNum && !isNaN(valueAsNum) ){
      //check if value is int and the input has a period
      if (Number.isInteger(valueAsNum)&& event.target.value.includes('.')){
        valueAsString =  valueAsNum + '.%' //add the period back
      } else {
        valueAsString =  valueAsNum + '%' //no need to add the period
      }
    }

    setCommission(valueAsString)
  }

  const validateCommission = (commission:any) => {
    let isValid= false;

    let commissionAsNum = Number.parseFloat(commission)

    if(commissionAsNum && !isNaN(commissionAsNum) ){
      if(commissionAsNum > 0 && commissionAsNum < 100){
        isValid=true;
      }
    }

    setCommissionValid(isValid)

    return isValid;
  }

  return (
    <div className='buying'>
        <TitleHeader titleText={'BUYING'} phraseText={'Save Thousands'} />
        <div className='buyer-info-section-row'>
          <div className='left-text'>
            <h2 className='_bold-header'>
            Split the Buyer's Agent Commission 50/50
            </h2>
            <div className='buyer-subtext-container'>
              <div className='buyer-sub-text-header'>
              Save money at closing.
              </div>
              <div className='buyer-sub-text'>
              Buyer's agent rebate can be applied directly to the closing cost.
              </div>
            </div>
            <Link to='/buying' >
              <div className='_learn-how-button'> Learn More</div>
                </Link>
          </div>

          <div className='right-image'>
           <div className='buyers-image'/>
          {/* <img className='buyers-image' src={buyerImage}/>
          <img className='buyers-image-mobile' src={buyerImageMobile}/>*/}
          </div>
        </div>
        <div className='buyer-calc'>
          <div className='calc-separator'>
          </div>
          <div className='calc-title'>
              Calculate Savings
          </div>
          <div className='calc-inputs-row'>
            <div className='input-col'>
              <label className='input-label'>Home Price</label>
              <input type='text' id='homeprice' value={homePrice} onChange={updateHomePrice} />
            </div>
            <div className='input-col'>
              <label className='input-label'>Buyer's Agent Commission</label>
              <input type='text' id='commission' value={commission} onChange={updateCommission} />
            </div>
          </div>
          {calcResult?<div className='calc-results'>
             Buyer Saves <span className='result-money'>${calcResult}</span>*
          </div>:
          <div  className='calc-results-error'>Enter a home price and a commission (i.e. 350000 and 2.5)</div>}
          <div className='disclaimer'>* The lender sets rules and limits to the amount of an agent rebate and how it can be used.
          <Link to='/rebate'>click here</Link> additonal details. </div>
        </div>
    </div>
  )
}
