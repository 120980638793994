import React, { FC } from 'react'
import './styles.scss';
import { ContentPage } from '../../content-page'


export const LearnMorePage: FC= () => {
  return (
    <>
    <div className='learn-more-image'/>
    <p>
      Jason is a full service real estate agent serving the Triangle Area since 2006.
      He covers all areas Triangle, including Cary, Morrisville, Apex, Raleigh, Durham, and Holly Springs.
    </p>
    <p>
      When selecting a real estate agent, you need one that knows the
      area well and can put your needs first. Jason Brown is an owner of the NC Realty Group, LLC and Broker-in-Charge of his office.
      He has lived in the triangle since 1999 and understands this area well. He has personally lived in Raleigh, Durham, and Cary.
</p>
<p>
      Jason truly calls the Triangle area his home and loves the perfect mix of culture, nature, and both slow and fast paced life all wrapped into one area. If you live in the Triangle,
      you can be at the beach in 2 hours or or the ski slopes in 3. There is an overabunance of parks and amazing trails. Want authentic and high-end restaurants? You'll find that too!
     </p>
      <p>
      Because Jason owns his own office, he is in a unique position to offer quality full service while saving you money. Since his overhead is very low, he can charge less than most firms to list a
      house and he'll even share his commission through an agent rebate to help you find a house.
    </p>
    </>
  )
}
