import React, { FC } from 'react'
import './styles.scss';
import { ContentPage } from '../../content-page'


export const BuyingPage: FC= () => {
  return (
    <>
    <div className='buy-page-image'/>
    <p>
      Jason is dedicated to finding you the right house. His philosopy is to be honest, straighforward,
      and put zero pressure on our clients. He can actually save you a lot of money by sharing his buyer's agent
      commission. </p><
      p>Typically, the seller pays all realtor fees in a real estate transaction and offers the buyer's
      agent a commission, agreed upon in the MLS. This is often a percentage of the price of the house. For example,
      it could be 2.4% or 3% of the sales price. This is how the buyers agent gets paid. Most agents need to give part of
      that money to their firm. However, Jason is the owner and Broker-in-Charge. This allows him to
      pass the money that most agents share with their firm onto his clients. This is a win/win situation!
    </p>
    </>
  )
}
