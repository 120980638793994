import React, { FC } from 'react'
import './styles.scss';

import { TitleHeader } from '../../../title-header'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export const References: FC= ({}) => {

  return (
    <div className='references'>
      <TitleHeader titleText={'REFERENCES'} phraseText={'Caring For Our Clients'} />
      <div className='_bold-header reference-header'>Serving the Triangle area with high quality service and huge savings since 2006</div>
      <Carousel className='carousel-component'
      infiniteLoop={true}
      showStatus={false}
      autoPlay={true}
      showThumbs={false}>
        <div className='carousel-section'>
        {/*  <img src={waltersImage} />*/}
          <div className='carousel-image  image-walters' />
          <div className='reference-text-section'>
            <div className='ref-name'>Brown & Dana W.</div>
            <div className='ref-city'>Cary, NC</div>
            <div className='separator'/>
            <div className='quote'>"...Jason helped us through a very smooth transaction, despite being in the beginning of the pandemic..."</div>
          </div>
        </div>
        <div className='carousel-section'>
            <div className='carousel-image  image-gillis' />
          <div className='reference-text-section'>
            <div className='ref-name'>Reubin & Paulette G.</div>
            <div className='ref-city'>Durham, NC</div>
            <div className='separator'/>
            <div className='quote'>"...we got a full service listing with a great price..."</div>
          </div>
        </div>
        <div className='carousel-section'>
        <div className='carousel-image image-jim-erin' />
            <div className='reference-text-section'>
              <div className='ref-name'>Jim and Erin S.</div>
              <div className='ref-city'>Cary, NC</div>
              <div className='separator'/>
              <div className='quote'>"...we really appreciated him splitting the commission. That helped a lot!..."</div>
          </div>
        </div>
        <div className='carousel-section'>
          <div className='carousel-image image-lisamom' />
          <div className='reference-text-section'>
            <div className='ref-name'>Lisa B.</div>
            <div className='ref-city'>Cary, NC</div>
            <div className='separator'/>
            <div className='quote'>"...we saved a lot of money and got a great service!..."</div>
          </div>
        </div>
        <div className='carousel-section'>
          <div className='carousel-image image-suj' />
          <div className='reference-text-section'>
            <div className='ref-name'>Suj & Bhargav P.</div>
            <div className='ref-city'>Durham, NC</div>
            <div className='separator'/>
            <div className='quote'>"...working with Jason was a great experience! He really understood what we wanted..."</div>
          </div>
        </div>
        <div className='carousel-section'>
          <div className='carousel-image image-nocha' />
          <div className='reference-text-section'>
            <div className='ref-name'>Nocha & Fernando F.</div>
            <div className='ref-city'>Cary, NC</div>
            <div className='separator'/>
            <div className='quote'>"...we found a house in the exact location we wanted. With his help it was a simple process!..."</div>
          </div>
        </div>
      </Carousel>
    </div>
  )
}
