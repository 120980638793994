import React, { FC } from 'react'
import './styles.scss';


export const TitleHeader: FC<{titleText:string,phraseText:string}>= (props) => {


  return (
    <div className='title-header'>
      <div className='border-row'>
        <div className='title-border-column'>
          <div className='title-row'>
             <h2 className='title'>
             {props.titleText}
             </h2>
             <div className='title-divider'>
             |
             </div>
             <h3 className='phrase'>
             {props.phraseText}
             </h3>
          </div>
          <div className='under-text-border'></div>
        </div>
        <div className='post-text-border'>
        </div>
      </div>
    </div>
  )
}
